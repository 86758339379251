import { Center, Stack, useMantineTheme } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AccessTokenContext } from '../../auth/AccessTokenProvider'
import { TertiaryButton } from '../style/buttons/TertiaryButton'
import { TextInput } from '../style/inputs/TextInput'
import { Text } from '../style/typography/Text'
import { TitleTwo } from '../style/typography/Title'

export const Login = () => {
  const authContext = useContext(AccessTokenContext)
  const navigate = useNavigate()

  const {
    other: { colors },
  } = useMantineTheme()

  const [error, setError] = useState<string | undefined>()

  const loginForm = useForm({
    initialValues: {
      username: '',
      password: '',
    },
    validate: {
      username: isNotEmpty('Required'),
      password: isNotEmpty('Required'),
    },
  })

  if (!authContext) {
    throw new Error('Component must be wrapped with <AccessTokenProvider>')
  }

  const { updateAccessToken } = authContext

  const handleLogin = () => {
    if (loginForm.validate().hasErrors) {
      return
    }

    const { username, password } = loginForm.values

    if (
      username === process.env.REACT_APP_AUTH_LOGIN &&
      password === process.env.REACT_APP_AUTH_PASSWORD
    ) {
      updateAccessToken(process.env.REACT_APP_AUTH_PASSWORD)
      navigate('/')
    } else {
      setError('Invalid username or password')
    }
  }

  return (
    <Center style={{ height: '100vh', width: '100%' }}>
      <Stack align='center' p='xl' w={400}>
        <TitleTwo>Login</TitleTwo>
        {error && <Text color={colors.orange[0]}>{error}</Text>}
        <TextInput
          placeholder='Username'
          {...loginForm.getInputProps('username')}
        ></TextInput>
        <TextInput
          placeholder='Password'
          {...loginForm.getInputProps('password')}
          type='password'
        ></TextInput>
        <TertiaryButton variant='filled' onClick={handleLogin} w='100%'>
          Log in
        </TertiaryButton>
      </Stack>
    </Center>
  )
}
