import { Group, Skeleton } from '@mantine/core'
import { useRef } from 'react'

export const ThingRowLoading = () => {
  const widthRef = useRef(Math.random() * 100)

  return (
    <Group noWrap py='sm'>
      <Skeleton height={20} circle />
      <Skeleton height={8} width={`${widthRef.current}%`} radius='xl' />
      <Skeleton height={20} circle />
      <Skeleton height={20} circle />
    </Group>
  )
}
