import { ReactNode, createContext, useState } from 'react'

export type AccessTokenContextValue = {
  accessToken: string | null
  updateAccessToken: (newAccessToken: string) => void
  isMatt: boolean
}

export const AccessTokenContext = createContext<AccessTokenContextValue | null>(
  null
)

interface AccessTokenProviderProps {
  children: ReactNode
}

export function AccessTokenProvider({ children }: AccessTokenProviderProps) {
  const [accessToken, setAccessToken] = useState<string | null>(() => {
    const storedAccessToken = localStorage.getItem('accessToken')
    return storedAccessToken ? JSON.parse(storedAccessToken) : null
  })

  const updateAccessToken = (newAccessToken: string) => {
    localStorage.setItem('accessToken', JSON.stringify(newAccessToken))
    setAccessToken(newAccessToken)
  }

  const isMatt = Boolean(accessToken)

  return (
    <AccessTokenContext.Provider
      value={{ accessToken, updateAccessToken, isMatt }}
    >
      {children}
    </AccessTokenContext.Provider>
  )
}
