// eslint-disable-next-line no-restricted-imports
import {
  Text as MantineText,
  TextProps as MantineTextProps,
  MantineThemeOther,
  useMantineTheme,
} from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/utils'
import React, { HTMLAttributeAnchorTarget, useMemo } from 'react'
import { Colors } from '../theme/base'

export type TextProps = Omit<
  MantineTextProps,
  'sx' | 'color' | 'size' | 'variant' | 'style'
> & {
  color?: string | ((colors: MantineThemeOther['colors']) => string) | undefined
  size?: 'md' | 'xs'
  bold?: boolean
}

function getLinkTextColor({
  colors,
  themeName,
}: {
  colors: Colors
  themeName: string
}) {
  if (themeName === 'daybreak') {
    return colors.black[0]
  }

  return colors.orange[0]
}

export type LinkTextProps = Omit<TextProps, 'color'>

export function Text<C = 'div'>({
  bold = false,
  color,
  size = 'md',
  ...rest
}: PolymorphicComponentProps<C, TextProps>) {
  const {
    other: { colors, fontFamilies },
  } = useMantineTheme()

  return (
    <MantineText<C>
      ff={bold ? fontFamilies.bold : fontFamilies.regular}
      lh='normal'
      color={
        (typeof color === 'string' ? color : color?.(colors)) ?? colors.black[0]
      }
      size={size}
      // Cast as any to avoid type error due to undefined props
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(rest as any)}
    />
  )
}

export function LinkText<C = 'div'>({
  ...rest
}: PolymorphicComponentProps<C, LinkTextProps>) {
  const {
    other: { fontFamilies, colors, themeName },
  } = useMantineTheme()

  return (
    <MantineText<C>
      ff={fontFamilies.bold}
      lh='normal'
      // Cast as any to avoid type error due to undefined props
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(rest as any)}
      sx={{
        color: getLinkTextColor({ colors, themeName }),
        whiteSpace: 'pre',
        ':hover': { color: colors.orange[0], textDecoration: 'underline' },
        ':focus': { outline: 'none', textDecoration: 'underline' },
      }}
    />
  )
}

export const ExternalLinkText = ({
  href,
  ...rest
}: PolymorphicComponentProps<'a', LinkTextProps>) => {
  const target = useMemo<HTMLAttributeAnchorTarget>(
    () =>
      ['tel:', 'sms:', 'mailto:', 'geo:'].some((tag) => href?.includes(tag))
        ? '_self'
        : '_blank',
    [href]
  )

  return (
    <LinkText
      href={href}
      component='a'
      target={target}
      rel='noopener noreferrer'
      {...rest}
    />
  )
}
