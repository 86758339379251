import { useCallback, useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { PrimaryButton } from '../style/buttons/PrimaryButton'
import { useMutation } from 'react-query'
import { blankApi } from '../api'

export const PlaidLinkButton = () => {
  const [linkToken, setLinkToken] = useState<string | null>(null)

  const createLinkTokenMutation = useMutation(
    blankApi.getMutation('POST /money/create-link-token'),
    {
      onSuccess: (data) => {
        const { linkToken } = data
        setLinkToken(linkToken)
      },
    }
  )

  const exchangePublicTokenMutation = useMutation(
    blankApi.getMutation('POST /money/exchange-public-token')
  )

  // Fetch the link token from your backend
  useEffect(() => {
    const fetchLinkToken = async () => {
      createLinkTokenMutation.mutateAsync({})
    }

    fetchLinkToken()
  }, [])

  const onSuccess = useCallback(async (publicToken: string) => {
    await exchangePublicTokenMutation.mutateAsync(
      {
        data: {
          publicToken,
        },
      },
      {
        onSuccess: (data) => {
          console.log('data', data)
        },
        onError: (error) => {
          console.log('error', error)
        },
      }
    )
  }, [])

  const config = {
    token: linkToken,
    onSuccess,
  }

  const { open, ready } = usePlaidLink(config)

  return (
    <PrimaryButton onClick={() => open()} disabled={!ready}>
      Connect an account
    </PrimaryButton>
  )
}
