import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './Routes'
import { AccessTokenProvider } from './auth/AccessTokenProvider'
import { SpotifyContextProvider } from './auth/SpotifyContextProvider'

function App(): JSX.Element {
  return (
    <Router>
      <AccessTokenProvider>
        <SpotifyContextProvider>
          <Routes />
        </SpotifyContextProvider>
      </AccessTokenProvider>
    </Router>
  )
}

export default App
