import { createEmotionCache, MantineProvider } from '@mantine/core'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from './App'
import { THEME } from './components/style/theme/master'

const appendCache = createEmotionCache({ key: 'mantine', prepend: false })

const queryClient = new QueryClient()

const Root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={THEME} emotionCache={appendCache}>
        <App />
      </MantineProvider>
    </QueryClientProvider>
  )
}

export default Root
