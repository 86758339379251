import {
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
} from '@mantine/core'

export const Select = (props: MantineSelectProps) => {
  return (
    <MantineSelect
      {...props}
      styles={({ other: { colors }, fontSizes }) => ({
        input: {
          borderColor: colors.orange[0],
          color: colors.orange[0],
          fontSize: fontSizes.md,
          fontWeight: 550,
        },
        label: {
          color: colors.black[0],
        },
        placeholder: {
          color: colors.black[0],
        },
        rightSection: {
          '> svg': {
            stroke: colors.orange[0],
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1.25px',
            '> path': {
              d: 'path("M 3.75 5.625 L 7.5 9.375 L 11.25 5.625")',
              fill: 'unset',
            },
          },
        },
      })}
    />
  )
}
