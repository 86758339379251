import { ReactNode, createContext, useEffect, useState } from 'react'

export type SpotifyContextProviderValue = {
  spotifyAccessToken: string | null
  updateSpotifyAccessToken: (newAccessToken: string) => void
  loginToSpotify: () => void
  isLoggedInToSpotify: boolean
}

export const SpotifyContext = createContext<SpotifyContextProviderValue>({
  spotifyAccessToken: null,
  updateSpotifyAccessToken: () => {},
  loginToSpotify: () => {},
  isLoggedInToSpotify: false,
})

type SpotifyContextProviderProps = {
  children: ReactNode
}

export const SpotifyContextProvider = ({
  children,
}: SpotifyContextProviderProps) => {
  const [spotifyAccessToken, setAccessToken] = useState<string | null>(() => {
    const storedAccessToken = localStorage.getItem('spotifyAccessToken')
    return storedAccessToken ? JSON.parse(storedAccessToken) : null
  })

  const updateSpotifyAccessToken = (newAccessToken: string) => {
    localStorage.setItem('spotifyAccessToken', JSON.stringify(newAccessToken))
    setAccessToken(newAccessToken)
  }

  useEffect(() => {
    const hash = window.location.hash
    let _token = window.localStorage.getItem('spotifyAccessToken')

    if (!_token && hash) {
      _token =
        hash
          .substring(1)
          .split('&')
          .find((elem) => elem.startsWith('access_token'))
          ?.split('=')[1] || null
      window.location.hash = ''
      window.localStorage.setItem('accessToken', JSON.stringify(_token!))
    }

    setAccessToken(_token)
  }, [])

  const loginToSpotify = () => {
    const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID
    const redirectUri = process.env.REACT_APP_SPOTIFY_REDIRECT_URI
    const scopes =
      'user-library-read user-read-playback-state user-modify-playback-state'
    window.location.href = `https://accounts.spotify.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}&response_type=token&show_dialog=true`
  }

  const isLoggedInToSpotify = Boolean(spotifyAccessToken)

  console.log('spotifyAccessToken', spotifyAccessToken)

  return (
    <SpotifyContext.Provider
      value={{
        spotifyAccessToken,
        updateSpotifyAccessToken,
        loginToSpotify,
        isLoggedInToSpotify,
      }}
    >
      {children}
    </SpotifyContext.Provider>
  )
}
