import { Category, CategoryName, Thing } from '@blanking/types'
import { Stack } from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { AccessTokenContext } from 'src/auth/AccessTokenProvider'
import { blankApi } from '../api'
import { useDimensions } from '../style/theme/use-dimensions'
import { ThingDrawer } from './ThingDrawer'
import { ThingsHeader } from './ThingsHeader'
import { ThingsSection } from './ThingsSection'
import { ThingsSectionEmptyState } from './ThingsSectionEmptyState'

export type Timeframe = 'all' | 'day' | 'week' | 'month' | 'year'

const now = dayjs()

export const Things = () => {
  const { isMobile } = useDimensions()
  const authContext = useContext(AccessTokenContext)

  const { isMatt } = authContext || { isMatt: false }

  const [selectedThing, setSelectedThing] = useState<Thing | null>(null)
  const [selectedParent, setSelectedParent] = useState<Thing | null>(null)
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    Category['id'] | null
  >()
  const [selectedCategories, setSelectedCategories] = useState<CategoryName[]>([
    'reading',
    'listening to',
  ])

  const [timeframe, setTimeframe] = useState<Timeframe>('all')
  const [drawerOpened, toggleDrawer] = useToggle()

  const thingsQuery = useQuery(
    ...blankApi.getQuery('GET /things', {
      query: {
        ...(timeframe !== 'all' && {
          startDate: now.subtract(1, timeframe).toISOString(),
        }),
        categoryNames: selectedCategories,
      },
    })
  )
  const things = thingsQuery?.data || []

  const categoriesQuery = useQuery(
    ...blankApi.getQuery('GET /categories', {
      query: { includePrivate: isMatt ? 'yes' : 'no' },
    })
  )
  const categories = categoriesQuery?.data || []

  const drawerOnClose = ({
    submitted,
    addingAnother,
  }: {
    submitted: boolean
    addingAnother: boolean
  }) => {
    if (submitted) {
      void thingsQuery.refetch()
    }

    if (addingAnother) {
      return
    }

    toggleDrawer()
    setSelectedThing(null)
    setSelectedParent(null)
    setSelectedCategoryId(null)
  }

  const hasSelectedCategories = selectedCategories.length > 0

  const onEditThing = (thing: Thing) => {
    setSelectedThing(thing)
    toggleDrawer()
  }

  const onAddChild = (thing: Thing) => {
    setSelectedParent(thing)
    toggleDrawer()
  }

  const onAddThing = (categoryName: CategoryName) => {
    const categoryId = categories.find(
      (category) => category.name === categoryName
    )?.id
    setSelectedCategoryId(categoryId)
    toggleDrawer()
  }

  return (
    <>
      <ThingDrawer
        opened={drawerOpened}
        onClose={drawerOnClose}
        thing={selectedThing || undefined}
        parent={selectedParent || undefined}
        categoryId={selectedCategoryId || undefined}
      />
      <Stack justify='center' p={isMobile ? 'md' : 'xl'} spacing='sm'>
        <ThingsHeader
          categories={categories}
          selectedCategories={selectedCategories}
          onSelectCategories={(categories: CategoryName[]) =>
            setSelectedCategories(categories)
          }
          onAddThing={() => toggleDrawer()}
          timeframe={timeframe}
          onChangeTimeframe={(timeframe: Timeframe) => setTimeframe(timeframe)}
          isLoading={categoriesQuery.isLoading}
        />
        {!hasSelectedCategories && (
          <ThingsSectionEmptyState
            onSelectCategories={(categories: CategoryName[]) =>
              setSelectedCategories(categories)
            }
          />
        )}
        {hasSelectedCategories &&
          selectedCategories.map((categoryName) => {
            return (
              <ThingsSection
                categoryName={categoryName}
                things={things.filter(
                  (thing) => thing.categories.name === categoryName
                )}
                onEditThing={onEditThing}
                onAddChild={onAddChild}
                onAddThing={onAddThing}
                isLoading={thingsQuery.isLoading}
              />
            )
          })}
      </Stack>
    </>
  )
}
