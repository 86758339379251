import {
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core'

export const TextInput = (props: MantineTextInputProps) => {
  return (
    <MantineTextInput
      {...props}
      sx={({ other: { colors }, fontSizes }) => ({
        width: '100%',
        borderBottom: `2px solid`,
        fontSize: fontSizes.xl,
        '::placeholder': {
          color: colors.orange[1],
        },
      })}
      variant='unstyled'
      radius={0}
    />
  )
}
