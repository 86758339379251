import { BaseApiConfig, BlankApi, BlankClient } from '@blanking/types'
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || '',
  headers: { 'Content-Type': 'application/json' },
  timeout: 10000,
})

const apiConfig: BaseApiConfig = { axiosInstance }
export const blankApi = new BlankClient<BlankApi>(apiConfig)
