import { Center, Stack } from '@mantine/core'
import { PrimaryButton } from '../style/buttons/PrimaryButton'

export const Fantasy = () => {
  return (
    <Center style={{ height: '100vh', width: '100%' }}>
      <Stack>
        <PrimaryButton>Play</PrimaryButton>
      </Stack>
    </Center>
  )
}
