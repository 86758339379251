import {
  CategoryName,
  Thing,
  ThingWithCategoryAndChildren,
} from '@blanking/types'
import { Group, Stack } from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import { useContext } from 'react'
import { AccessTokenContext } from 'src/auth/AccessTokenProvider'
import { IconButton } from '../style/buttons/IconButton'
import { MinusIcon, PlusIcon, PlusSquareIcon } from '../style/icons/Icons'
import { TitleThree } from '../style/typography/Title'
import { ThingRow } from './ThingRow'
import { ThingRowLoading } from './ThingRowLoading'

type ThingsSectionProps = {
  categoryName: CategoryName
  things: ThingWithCategoryAndChildren[]
  onEditThing: (thing: Thing) => void
  onAddChild: (thing: Thing) => void
  onAddThing: (categoryName: CategoryName) => void
  isLoading: boolean
}

export const ThingsSection = ({
  categoryName,
  things,
  onEditThing,
  onAddChild,
  onAddThing,
  isLoading,
}: ThingsSectionProps) => {
  const authContext = useContext(AccessTokenContext)
  const { isMatt } = authContext || { isMatt: false }

  const [displayThings, toggleThings] = useToggle([true, false])

  return (
    <Stack mb='md'>
      <Group
        spacing='md'
        style={{
          cursor: 'pointer',
        }}
        onClick={() => toggleThings()}
      >
        <IconButton
          icon={displayThings ? <MinusIcon /> : <PlusIcon />}
          disabled
        />
        <TitleThree>{`${categoryName[0].toUpperCase()}${categoryName.slice(
          1
        )}`}</TitleThree>
        {isMatt && (
          <IconButton
            icon={<PlusSquareIcon />}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              onAddThing(categoryName)
            }}
          />
        )}
      </Group>
      {isLoading && [...Array(5)].map((i) => <ThingRowLoading key={i} />)}
      {displayThings &&
        things.map((thing) => (
          <ThingRow
            thing={thing}
            onEdit={onEditThing}
            onAddChild={onAddChild}
          />
        ))}
    </Stack>
  )
}
