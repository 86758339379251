import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
  useMantineTheme,
} from '@mantine/core'
import { ReactNode } from 'react'

interface IconButtonProps extends MantineButtonProps {
  icon?: ReactNode
  onClick?: (e: React.MouseEvent) => void
}

export const IconButton = (props: IconButtonProps) => {
  const { icon, children, size, variant = 'subtle', ...others } = props
  const {
    other: { colors, sizes },
    fontSizes,
  } = useMantineTheme()
  return (
    <MantineButton
      {...(others as MantineButtonProps)}
      radius='sm'
      size={size}
      variant={variant}
      px={sizes.padding.sm}
      mx={sizes.padding.sm}
      sx={{
        backgroundColor:
          variant === 'filled' ? colors.orange[0] : 'transparent',
        color: colors.orange[0],
        borderColor: variant === 'outline' ? colors.orange[0] : 'transparent',
        fontSize: fontSizes[size || 'md'],
        '&:hover': {
          backgroundColor: colors.white[2],
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: colors.white[0],
          color: colors.orange[0],
          boxShadow: 'none',
        },
      }}
    >
      {icon}
    </MantineButton>
  )
}
