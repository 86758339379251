// eslint-disable-next-line no-restricted-imports
import {
  MantineThemeOther,
  Title as MantineTitle,
  TitleProps as MantineTitleProps,
} from '@mantine/core'

type TitleProps = Omit<MantineTitleProps, 'order' | 'color' | 'sx'> & {
  color?: string | ((colors: MantineThemeOther['colors']) => string)
}

export const TitleOne = ({ color, ...rest }: TitleProps) => {
  return (
    <MantineTitle
      order={1}
      sx={[
        ({ other: { colors } }) => ({
          lineHeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'auto',
          color:
            (typeof color === 'string' ? color : color?.(colors)) ?? 'inherit',
          letterSpacing: '-0.01em',
        }),
      ]}
      {...rest}
    />
  )
}

export const TitleTwo = ({ color, ...rest }: TitleProps) => {
  return (
    <MantineTitle
      order={2}
      sx={[
        ({ other: { colors } }) => ({
          lineHeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'auto',
          color:
            (typeof color === 'string' ? color : color?.(colors)) ?? 'inherit',
        }),
      ]}
      {...rest}
    />
  )
}

export const TitleThree = ({ color, ...rest }: TitleProps) => {
  return (
    <MantineTitle
      order={3}
      sx={[
        ({ other: { colors } }) => ({
          lineHeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'auto',
          color:
            (typeof color === 'string' ? color : color?.(colors)) ?? 'inherit',
          letterSpacing: '0.02em',
        }),
      ]}
      {...rest}
    />
  )
}

export const TitleFour = ({ color, ...rest }: TitleProps) => {
  return (
    <MantineTitle
      order={4}
      sx={[
        ({ other: { colors, fontFamilies } }) => ({
          lineHeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'auto',
          color:
            (typeof color === 'string' ? color : color?.(colors)) ?? 'inherit',
          fontFamily: fontFamilies.bold,
          textTransform: 'uppercase',
          letterSpacing: '0.02em',
        }),
      ]}
      {...rest}
    />
  )
}
