import React from 'react'
import { Route, Routes as RouteSwitch } from 'react-router-dom'
import { Login } from './components/auth/Login'
import { Things } from './components/things/Things'
import { ThingPage } from './components/things/ThingPage'
import { Money } from './components/money/Money'
// import { PodPlayer } from './components/podcasts/Player'
import { Fantasy } from './components/fantasy/Fantasy'

export const RoutePaths = {}

const Routes: React.FC = () => {
  return (
    <RouteSwitch>
      <Route path='/' element={<Things />} />
      <Route path='/matt' element={<Login />} />
      <Route path='/money' element={<Money />} />
      {/* <Route path='/pods' element={<PodPlayer />} /> */}
      <Route path='/fantasy' element={<Fantasy />} />
      <Route path='/thing/:thingId' element={<ThingPage />} />
    </RouteSwitch>
  )
}

export default Routes
