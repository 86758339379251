import { Thing, ThingWithCategoryAndChildren } from '@blanking/types'
import {
  Box,
  CopyButton,
  Group,
  Modal,
  Stack,
  useMantineTheme,
} from '@mantine/core'
import { isEmail, useForm } from '@mantine/form'
import { useToggle } from '@mantine/hooks'
import { useContext } from 'react'
import { useMutation } from 'react-query'
import { AccessTokenContext } from '../../auth/AccessTokenProvider'
import { blankApi } from '../api'
import { IconButton } from '../style/buttons/IconButton'
import { PrimaryButton } from '../style/buttons/PrimaryButton'
import {
  BookIcon,
  CheckIcon,
  ExternalLinkIcon,
  LinkIcon,
  MinusIcon,
  PlusIcon,
  PlusSquareIcon,
} from '../style/icons/Icons'
import { TextInput } from '../style/inputs/TextInput'
import { useDimensions } from '../style/theme/use-dimensions'
import { Text } from '../style/typography/Text'

type ThingProps = {
  thing: ThingWithCategoryAndChildren
  onEdit: (thing: Thing) => void
  onAddChild: (thing: Thing) => void
  displayAllChildren?: boolean
}

export const ThingRow = ({
  thing,
  onEdit,
  onAddChild,
  displayAllChildren = false,
}: ThingProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const authContext = useContext(AccessTokenContext)

  const { isMobile } = useDimensions()

  const [displayChildren, toggleChildrenVisible] = useToggle(
    displayAllChildren ? [true, false] : [false, true]
  )
  const [displayShareModal, toggleShareModal] = useToggle()

  const { isMatt } = authContext || { isMatt: false }

  const shareForm = useForm({
    initialValues: {
      email: isMatt ? 'mattblank11@kindle.com' : '',
    },
    validate: {
      email: isEmail(),
    },
  })

  const hasChildren = (thing.children?.length || 0) > 0

  const shareToKindle = useMutation(
    blankApi.getMutation('POST /thing/:thingId/share-to-kindle')
  )

  const onShareToKindle = (thing: Thing) => {
    if (shareForm.validate().hasErrors) {
      if (!displayShareModal) {
        toggleShareModal(true)
      }
      return
    }

    shareToKindle.mutateAsync({
      params: {
        thingId: thing.id,
      },
      data: {
        email: shareForm.values.email,
      },
    })
  }

  return (
    <>
      <Modal
        opened={displayShareModal}
        onClose={() => onShareToKindle(thing)}
        centered
      >
        <Stack>
          <Text>
            Enter the email address associated with your Kindle account:
          </Text>
          <TextInput {...shareForm.getInputProps('email')} />
          <PrimaryButton onClick={() => onShareToKindle(thing)}>
            Send
          </PrimaryButton>
        </Stack>
      </Modal>
      <Stack spacing='sm'>
        <Group
          spacing='xs'
          onClick={() => {
            if (!hasChildren) {
              return
            }
            toggleChildrenVisible()
          }}
          sx={{ cursor: hasChildren ? 'pointer' : 'default' }}
          noWrap
        >
          <Group noWrap>
            {
              <>
                <IconButton
                  disabled={!hasChildren}
                  icon={
                    displayChildren || !hasChildren ? (
                      <MinusIcon />
                    ) : (
                      <PlusIcon />
                    )
                  }
                />
                <Text
                  bold
                  onClick={(e: React.MouseEvent) => {
                    if (isMatt) {
                      e.stopPropagation()
                      onEdit(thing)
                    }
                  }}
                >
                  {thing.thing}
                </Text>
                {thing.link && (
                  <IconButton
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation()
                      window.open(thing.link || '', '_blank')
                    }}
                    icon={<ExternalLinkIcon />}
                  >
                    {isMobile ? null : 'Link'}
                  </IconButton>
                )}
                <CopyButton value={`${window.location.host}/thing/${thing.id}`}>
                  {({ copied, copy }) => (
                    <IconButton
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
                        copy()
                      }}
                      icon={
                        copied ? (
                          <CheckIcon color={colors.success[0]} />
                        ) : (
                          <LinkIcon />
                        )
                      }
                    />
                  )}
                </CopyButton>
              </>
            }
            {isMatt && (
              <IconButton
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  onAddChild(thing)
                }}
                icon={<PlusSquareIcon />}
              />
            )}
          </Group>
        </Group>
        {displayChildren &&
          thing.children?.map((child) => {
            return (
              <Box pl={isMobile ? 'md' : 'lg'}>
                <ThingRow
                  key={child.id}
                  thing={child}
                  onEdit={onEdit}
                  onAddChild={onAddChild}
                  displayAllChildren
                />
              </Box>
            )
          })}
      </Stack>
    </>
  )
}
