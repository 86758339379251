import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
} from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/utils'
import { ButtonChildren, ButtonProps } from './buttons.helpers'

type TertiaryButtonProps<
  T extends ButtonChildren = string,
  C = 'div'
> = PolymorphicComponentProps<
  C,
  ButtonProps<T> & {
    size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  }
>

export const TertiaryButton = (props: TertiaryButtonProps) => {
  const { children, active, size, variant = 'filled', ...others } = props

  return (
    <MantineButton
      {...(others as MantineButtonProps)}
      radius='sm'
      sx={({ other: { colors }, fontSizes }) => ({
        backgroundColor: variant === 'filled' ? colors.white[1] : 'transparent',
        color: colors.orange[0],
        borderColor: variant === 'outline' ? colors.white[1] : 'transparent',
        fontSize: fontSizes[size || 'md'],
        '&:hover': {
          backgroundColor: colors.white[2],
          boxShadow: 'none',
        },
      })}
    >
      {children}
    </MantineButton>
  )
}
