import { Thing } from '@blanking/types/dist/models'
import { Group, Stack } from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { blankApi } from '../api'
import { TertiaryButton } from '../style/buttons/TertiaryButton'
import { ArrowLeftIcon } from '../style/icons/Icons'
import { useDimensions } from '../style/theme/use-dimensions'
import { ThingDrawer } from './ThingDrawer'
import { ThingRow } from './ThingRow'

export type Timeframe = 'all' | 'day' | 'week' | 'month' | 'year'

export const ThingPage = () => {
  const { isMobile } = useDimensions()

  const navigate = useNavigate()

  const [selectedThing, setSelectedThing] = useState<Thing | null>(null)
  const [selectedParent, setSelectedParent] = useState<Thing | null>(null)

  const [drawerOpened, toggleDrawer] = useToggle()

  const drawerOnClose = ({
    submitted,
    addingAnother,
  }: {
    submitted: boolean
    addingAnother: boolean
  }) => {
    if (submitted) {
      void thingsQuery.refetch()
    }

    if (addingAnother) {
      return
    }

    toggleDrawer()
    setSelectedThing(null)
    setSelectedParent(null)
  }

  const { thingId } = useParams()

  const thingsQuery = useQuery(
    ...blankApi.getQuery('GET /things/:thingId', {
      params: {
        thingId: thingId || '',
      },
    })
  )
  const things = thingsQuery?.data || []

  const onEditThing = (thing: Thing) => {
    setSelectedThing(thing)
    toggleDrawer()
  }

  const onAddChild = (thing: Thing) => {
    setSelectedParent(thing)
    toggleDrawer()
  }

  return (
    <>
      <ThingDrawer
        opened={drawerOpened}
        onClose={drawerOnClose}
        thing={selectedThing || undefined}
        parent={selectedParent || undefined}
      />
      <Stack justify='center' p={isMobile ? 'md' : 'xl'} spacing='sm'>
        <Group position='left'>
          <TertiaryButton
            leftIcon={<ArrowLeftIcon />}
            variant='subtle'
            onClick={() => navigate('/')}
          >
            Go home
          </TertiaryButton>
        </Group>
        {things.map((thing) => (
          <ThingRow
            thing={thing}
            onEdit={onEditThing}
            onAddChild={onAddChild}
            displayAllChildren
          />
        ))}
      </Stack>
    </>
  )
}
