import { Category, Thing } from '@blanking/types'
import { Drawer, Grid, Group, Stack, TextInput, Textarea } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { useContext, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AccessTokenContext } from 'src/auth/AccessTokenProvider'
import { blankApi } from '../api'
import { TertiaryButton } from '../style/buttons/TertiaryButton'
import { Select } from '../style/inputs/Select'
import { Text } from '../style/typography/Text'
import { TitleTwo } from '../style/typography/Title'

type ThingDrawerProps = {
  opened: boolean
  onClose: ({
    submitted,
    addingAnother,
  }: {
    submitted: boolean
    addingAnother: boolean
  }) => void
  thing?: Thing
  parent?: Thing
  categoryId?: Category['id']
}

export const ThingDrawer = ({
  opened,
  onClose,
  thing,
  parent,
  categoryId,
}: ThingDrawerProps) => {
  const authContext = useContext(AccessTokenContext)
  const { isMatt } = authContext || { isMatt: false }
  const categoriesQuery = useQuery(
    ...blankApi.getQuery('GET /categories', {
      query: { includePrivate: isMatt ? 'yes' : 'no' },
    })
  )
  const categories = categoriesQuery?.data || []
  const categorySelectOptions = categories.map((category) => ({
    value: category.id,
    label: `${category.name[0].toUpperCase()}${category.name.slice(1)}`,
  }))

  const initialCategoryId =
    categoryId || thing?.categoryId || parent?.categoryId || ''

  const categoryName = categories.find(
    (category) => category.id === initialCategoryId
  )?.name

  const thingForm = useForm({
    initialValues: {
      categoryId: initialCategoryId,
      subcategory: thing?.subcategory || null,
      thing: thing?.thing || '',
      parentId: parent?.id || thing?.parentId || null,
      link: thing?.link || null,
      image: thing?.image || null,
    },
    validate: {
      categoryId: isNotEmpty('Required'),
      thing: isNotEmpty('Required'),
    },
  })

  console.log('thing', thing)

  const createAndCloseThing = useMutation(
    blankApi.getMutation('POST /things'),
    {
      onSuccess: () => {
        thingForm.reset()
        onClose({ submitted: true, addingAnother: false })
      },
    }
  )

  const createAndAddThing = useMutation(blankApi.getMutation('POST /things'), {
    onSuccess: () => {
      thingForm.reset()
      thingForm.setValues({
        categoryId: parent?.categoryId,
        parentId: parent?.id || null,
      })
    },
  })

  const createAndAddChild = useMutation(blankApi.getMutation('POST /things'), {
    onSuccess: (newThing: Thing) => {
      thingForm.reset()
      thingForm.setValues({
        categoryId: newThing?.categoryId,
        parentId: newThing.id || null,
      })
    },
  })

  const createAndAddParent = useMutation(blankApi.getMutation('POST /things'), {
    onSuccess: (newThing: Thing) => {
      console.log()
      thingForm.reset()
      thingForm.setValues({
        categoryId: newThing?.categoryId,
        parentId: parent?.parentId || null,
      })
    },
  })

  const updateThing = useMutation(
    blankApi.getMutation('PUT /things/:thingId'),
    {
      onSuccess: () => {
        thingForm.reset()
        onClose({ submitted: true, addingAnother: false })
      },
    }
  )

  const deleteThing = useMutation(
    blankApi.getMutation('DELETE /things/:thingId'),
    {
      onSuccess: () => {
        thingForm.reset()
        onClose({ submitted: true, addingAnother: false })
      },
    }
  )

  const submitThingForm = ({
    close,
    addChild = false,
    addParent = false,
  }: {
    close: Boolean
    addChild?: boolean
    addParent?: boolean
  }) => {
    if (thingForm.validate().hasErrors) {
      return
    }

    if (thing?.id) {
      updateThing.mutate({
        params: { thingId: thing.id },
        data: { thing: thingForm.values },
      })
      return
    }

    if (close) {
      createAndCloseThing.mutate({
        data: { thing: thingForm.values },
      })
      return
    }

    if (addChild) {
      createAndAddChild.mutate({
        data: { thing: thingForm.values },
      })
      return
    }

    if (addParent) {
      createAndAddParent.mutate({
        data: { thing: thingForm.values },
      })
      return
    }

    createAndAddThing.mutate({
      data: { thing: thingForm.values },
    })
  }

  useEffect(() => {
    if (thing) {
      thingForm.setValues({
        categoryId: thing.categoryId,
        subcategory: thing.subcategory,
        thing: thing.thing,
        parentId: thing?.parentId || null,
        link: thing.link,
        image: thing.image,
      })
    }
  }, [thing])

  useEffect(() => {
    if (parent) {
      thingForm.setValues({
        categoryId: parent?.categoryId,
        parentId: parent?.id || null,
      })
    }
  }, [parent])

  useEffect(() => {
    if (categoryId) {
      thingForm.setValues({
        categoryId,
      })
    }
  }, [categoryId])

  return (
    <Drawer
      opened={opened}
      onClose={() => {
        onClose({ submitted: false, addingAnother: false })
        thingForm.reset()
      }}
      position='right'
      size='lg'
      title={
        <TitleTwo>
          {thing ? 'Edit' : 'New'} thing
          {categoryName ? ` I'm ${categoryName}` : ''}
        </TitleTwo>
      }
      lockScroll={false}
    >
      <Stack justify='space-between'>
        {parent && <Text>{parent.thing}</Text>}
        <Grid>
          <Grid.Col span={12}>
            <Select
              label='Category'
              data={categorySelectOptions}
              placeholder='Select category'
              {...thingForm.getInputProps('categoryId')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              label='Thing'
              minRows={1}
              placeholder={`Thing${categoryName ? ` I'm ${categoryName}` : ''}`}
              autosize
              {...thingForm.getInputProps('thing')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label='Link'
              placeholder='Add link'
              {...thingForm.getInputProps('link')}
            />
          </Grid.Col>
        </Grid>
        <Group position={thing ? 'apart' : 'right'}>
          {thing && (
            <TertiaryButton
              variant='subtle'
              onClick={() =>
                deleteThing.mutate({ params: { thingId: thing.id } })
              }
            >
              Delete
            </TertiaryButton>
          )}
          <Group>
            <TertiaryButton
              onClick={() => submitThingForm({ close: true })}
              variant='subtle'
            >
              Save
            </TertiaryButton>
            <TertiaryButton
              onClick={() => submitThingForm({ close: false })}
              variant='subtle'
            >
              Add another
            </TertiaryButton>
            <TertiaryButton
              onClick={() => submitThingForm({ close: false, addParent: true })}
              variant='subtle'
            >
              Add parent
            </TertiaryButton>
            <TertiaryButton
              onClick={() => submitThingForm({ close: false, addChild: true })}
            >
              Add child
            </TertiaryButton>
          </Group>
        </Group>
      </Stack>
    </Drawer>
  )
}
