import { Group, Skeleton } from '@mantine/core'

export const CategoryHeaderLoading = () => {
  return (
    <Group spacing='xs' noWrap w={85}>
      <Skeleton height={10} circle />
      <Skeleton height={10} radius='xl' />
    </Group>
  )
}
