import { Center, Stack } from '@mantine/core'
import { PlaidLinkButton } from './PlaidLinkButton'

export const Money = () => {
  return (
    <Center style={{ height: '100vh', width: '100%' }}>
      <Stack>
        <PlaidLinkButton />
      </Stack>
    </Center>
  )
}
