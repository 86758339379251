import { useQuery } from 'react-query'
import { blankApi } from '../api'
import { useEffect, useState } from 'react'

const getRandomInt = ({ max }: { max: number }) => {
  return Math.floor(Math.random() * max)
}

export const useRandomThing = () => {
  const thingsQuery = useQuery(
    ...blankApi.getQuery('GET /things', {
      query: {
        categoryNames: ['listening to', 'reading', 'thinking'],
      },
    })
  )
  const things = thingsQuery?.data || []

  const [randomIndex, setRandomIndex] = useState(() =>
    getRandomInt({ max: things.length })
  )

  useEffect(() => {
    const timer = setInterval(() => {
      setRandomIndex(getRandomInt({ max: things.length }))
    }, 4000)

    // Clear interval on unmount
    return () => clearInterval(timer)
  }, [things.length])

  return {
    thingToDisplay: things[randomIndex],
    isLoading: thingsQuery.isLoading,
  }
}
