import { createRoot } from 'react-dom/client'
import Root from './Root'
import './index.css'

void (async () => {
  const container = document.getElementById('root')
  const root = createRoot(container as HTMLElement)

  root.render(<Root />)
})()
