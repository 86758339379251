// eslint-disable-next-line no-restricted-imports
import { createStyles, CSSObject, MantineThemeOther } from "@mantine/core";

type IconSize = keyof MantineThemeOther["sizes"]["icon"];

export type StyleProps = {
  // Changes the icon's colors to a variant used for actions (like buttons)
  styled?: boolean;
  color?: string | ((colors: MantineThemeOther["colors"]) => string);
  size?: IconSize;
};

export type StyledCSS = (secondaryColor: string) => CSSObject;

export const useIconStyle = createStyles(
  (
    { other: { themeName, colors, sizes } },
    {
      styled,
      color,
      size,
      styling,
    }: Required<StyleProps> & { styling: StyledCSS | undefined }
  ) => {
    if (!styled) {
      const primaryColor = typeof color === "string" ? color : color(colors);
      const secondaryColor = typeof color === "string" ? color : color(colors);

      return {
        icon: {
          flex: "none",
          width: sizes.icon[size],
          height: sizes.icon[size],
          color: primaryColor,
          ...styling?.(secondaryColor),
        },
      };
    }

    if (themeName === "daybreak") {
      const primaryColor = colors.black[3];
      const secondaryColor = colors.orange[0];

      return {
        icon: {
          flex: "none",
          width: sizes.icon[size],
          height: sizes.icon[size],
          color: primaryColor,
          ...styling?.(secondaryColor),
        },
      };
    }

    const primaryColor = colors.orange[0];
    const secondaryColor = colors.gray[1];

    // Default to twilight
    return {
      icon: {
        width: sizes.icon[size],
        height: sizes.icon[size],
        color: primaryColor,
        ...styling?.(secondaryColor),
      },
    };
  }
);
