/* eslint-disable no-magic-numbers */
// eslint-disable-next-line no-restricted-imports
import { MantineThemeOverride } from '@mantine/core'
import { baseTheme, Colors, createHomogenousColorList, Fonts } from './base'

export const COLORS: Colors = {
  success: ['#00B0AD', '#CCEFEF'],
  warning: ['#FFB656', '#FFF0DD'],
  error: ['#DF0101', '#F9CCCC'],
  orange: ['#F88B40', '#F7A072', '#F4976C', '#F2B388', '#FDB766', '#FFD09C'],
  black: ['#000000', '#373A40', '#2C2E33', '#25262b', '#1A1B1E'],
  white: ['#FFFFFF', '#FFF4E2', '#FFE9CC'],
  blue: ['#3F7CAC', '#386FA4', '#476C9B'],
  gray: ['#F6F4F6', '#C1C2C5', '#A6A7AB', '#909296', '#5c5f66'],
}

// These are imported in the <Global> component.
export const FONT_FAMILIES: Fonts = {
  regular: 'Inter, sans-serif',
  bold: 'Inter, sans-serif',
  pro: 'Inter, sans-serif',
}

export const THEME: MantineThemeOverride = {
  ...baseTheme,
  colorScheme: 'light',
  fontFamily: FONT_FAMILIES.regular,
  colors: {
    // Dark is the default dark, with the exception of the first two.
    dark: [
      '#FFFFFF',
      '#F6F4F6',
      '#C1C2C5',
      '#A6A7AB',
      '#909296',
      '#5c5f66',
      '#373A40',
      '#2C2E33',
      '#25262b',
      '#1A1B1E',
    ],
    primary: createHomogenousColorList(COLORS.orange[0]),
    // NOTE: this overrides all the RED colors
    red: createHomogenousColorList(COLORS.error[0]),
    // NOTE: this overrides all the GREEN colors
    green: createHomogenousColorList(COLORS.success[0]),
    // NOTE: this overrides all the YELLOW colors
    yellow: createHomogenousColorList(COLORS.warning[0]),
  },
  headings: {
    fontFamily: FONT_FAMILIES.regular,
    sizes: {
      h1: {
        fontSize: '4rem',
      },
      h2: {
        fontSize: '1.5rem',
      },
      h3: {
        fontSize: '1.25rem',
      },
      h4: {
        fontSize: '0.875rem',
      },
    },
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1rem',
  },
  other: {
    colors: COLORS,
    fontFamilies: FONT_FAMILIES,
    themeName: 'daybreak',
    emoji: {
      error: `""`,
      warning: `""`,
      success: `""`,
    },
    sizes: {
      iconbutton: { xs: '1rem', sm: '1rem', md: '1.5rem', lg: '2rem' },
      buttonpadding: { xs: '0.25rem', sm: '0.25rem', md: '0.5rem' },
      button: { xs: '1.625rem', sm: '1.625rem', md: '2.313rem' },
      input: { md: '2.25rem' },
      padding: {
        xs: '0.125rem',
        sm: '0.25rem',
        md: '0.5rem',
        lg: '0.75rem',
        xl: '1rem',
      },
      gap: { sm: '0.25rem', md: '0.5rem', lg: '0.75rem', xl: '1rem' },
      icon: { sm: '1rem', md: '1rem', lg: '1.5rem', xl: '2rem' },
      border: { sm: '.03rem', md: '0.094rem' },
      outline: { sm: '0.094rem', md: '0.125rem', lg: '0.188rem' },
    },
  },
}
