import { CategoryName } from '@blanking/types'
import { Center, Stack, useMantineTheme } from '@mantine/core'
import { TitleThree } from '../style/typography/Title'
import { Text } from '../style/typography/Text'
import { ThingRowLoading } from './ThingRowLoading'
import { useRandomThing } from './useRandomThing'
import { useDimensions } from '../style/theme/use-dimensions'

type ThingsSectionEmptyStateProps = {
  onSelectCategories: (categories: CategoryName[]) => void
}

export const ThingsSectionEmptyState = ({
  onSelectCategories,
}: ThingsSectionEmptyStateProps) => {
  const { thingToDisplay, isLoading } = useRandomThing()
  const { isMobile } = useDimensions()
  const {
    other: { colors },
  } = useMantineTheme()

  if (isLoading) {
    return null
  }

  const {
    thing,
    categories: { name: categoryName },
  } = thingToDisplay

  return (
    <Center style={{ height: '70vh', width: '100%' }}>
      <Stack w={isMobile ? '80%' : '60%'}>
        <TitleThree style={{ overflow: '' }}>
          Check out some of the things I'm{' '}
          <span
            onClick={() => onSelectCategories([categoryName])}
            style={{ cursor: 'pointer', color: colors.orange[0] }}
            color={colors.orange[0]}
          >
            {categoryName.toLowerCase()}
          </span>
          , like:
        </TitleThree>
        <TitleThree>{thing}</TitleThree>
      </Stack>
    </Center>
  )
}
