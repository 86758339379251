import { Avatar, Badge, Group, Stack, useMantineTheme } from '@mantine/core'
import { Category, CategoryName } from '@blanking/types'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AccessTokenContext } from 'src/auth/AccessTokenProvider'
import { TitleThree } from '../style/typography/Title'
import { Text } from '../style/typography/Text'
import { useDimensions } from '../style/theme/use-dimensions'
import { IconButton } from '../style/buttons/IconButton'
import { CheckIcon, PlusIcon, PlusSquareIcon } from '../style/icons/Icons'
import { Timeframe } from './Things'
import { Select } from '../style/inputs/Select'
import { CategoryHeaderLoading } from './CategoryHeaderLoading'

const timeframeSelectOptions = [
  { value: 'all', label: 'All time' },
  { value: 'day', label: 'Past day' },
  { value: 'week', label: 'Past week' },
  { value: 'month', label: 'Past month' },
  { value: 'year', label: 'Past year' },
]

type ThingsHeaderProps = {
  categories: Category[]
  selectedCategories: CategoryName[]
  onSelectCategories: (categories: CategoryName[]) => void
  onAddThing: () => void
  timeframe: Timeframe
  onChangeTimeframe: (timeframe: Timeframe) => void
  isLoading: boolean
}

export const ThingsHeader = ({
  categories,
  selectedCategories,
  onSelectCategories,
  onAddThing,
  timeframe,
  onChangeTimeframe,
  isLoading,
}: ThingsHeaderProps) => {
  const { isMobile } = useDimensions()
  const navigate = useNavigate()
  const authContext = useContext(AccessTokenContext)

  const {
    other: { colors },
  } = useMantineTheme()

  const { isMatt } = authContext || { isMatt: false }

  const handleSelectedCategories = (categoryName: CategoryName) => {
    const isSelected = selectedCategories.includes(categoryName)
    const removeSelection = selectedCategories.filter(
      (category) => category !== categoryName
    )
    const addSelection = [...selectedCategories, categoryName]
    onSelectCategories(isSelected ? removeSelection : addSelection)
  }

  const allCategoriesSelected = categories.every((category) =>
    selectedCategories.includes(category.name)
  )

  const toggleAllCategoriesSelected = () => {
    onSelectCategories(
      allCategoriesSelected ? [] : categories.map((category) => category.name)
    )
  }

  return (
    <Group position='apart' noWrap mb='sm'>
      <Stack>
        <Group noWrap>
          <Avatar src={'/favicon.ico'} onClick={() => navigate('/')} />
          <Group spacing='sm'>
            <TitleThree>Things I'm</TitleThree>
            {isLoading &&
              [...Array(7)].map((i) => <CategoryHeaderLoading key={i} />)}
            {categories.map((category) => {
              const isSelected = selectedCategories.includes(category.name)
              return (
                <Badge
                  key={category.id}
                  onClick={() => handleSelectedCategories(category.name)}
                  variant={isSelected ? 'filled' : 'outline'}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: isSelected
                      ? colors.orange[1]
                      : colors.white[0],
                    textTransform: 'lowercase',
                  }}
                >
                  <Group spacing='xs'>
                    {isSelected && <CheckIcon />}
                    <Text size='xs'>{category.name.toLowerCase()}</Text>
                  </Group>
                </Badge>
              )
            })}
            <Badge
              onClick={() => toggleAllCategoriesSelected()}
              variant={allCategoriesSelected ? 'filled' : 'outline'}
              style={{
                cursor: 'pointer',
                borderColor: colors.black[1],
                backgroundColor: allCategoriesSelected
                  ? colors.black[1]
                  : colors.white[0],
                color: colors.orange[1],
                textTransform: 'lowercase',
              }}
            >
              <Group spacing='xs'>
                {allCategoriesSelected && <CheckIcon />}
                <Text
                  color={
                    allCategoriesSelected ? colors.white[0] : colors.black[0]
                  }
                  size='xs'
                >
                  All the things
                </Text>
              </Group>
            </Badge>
          </Group>
        </Group>
      </Stack>
      <Group>
        {isMatt && (
          <IconButton
            icon={<PlusIcon />}
            onClick={onAddThing}
            variant='outline'
          />
        )}
        {!isMobile && (
          <Select
            value={timeframe}
            onChange={onChangeTimeframe}
            data={timeframeSelectOptions}
          />
        )}
      </Group>
    </Group>
  )
}
